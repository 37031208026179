import React from "react";

import BlogPreview from "../Elements/EntryPreview";

import ShowSkeletons from "./ShowSkeletons";

const ShowEntrysByName = ({ data }) => {
	return (
		<>
			<div className="flex flex-col justify-evenly items-center md:items-start md:flex-row md:flex-wrap md:w-11/12 m-auto">
				{data.length > 0 ? (
					data.map((entry, idx) => (
						<BlogPreview
							key={idx}
							id={entry.ref.value.id}
							title={entry.data.title}
							author={entry.data.author}
							avatar={entry.data.avatar}
							date={entry.data.created__at}
							tags={entry.data.tags}
						/>
					))
				) : (
					<ShowSkeletons />
				)}
			</div>
		</>
	);
};

export default ShowEntrysByName;
