import "filepond/dist/filepond.min.css";

import React, { useRef } from "react";

import axios from "axios";
import { createMaterial } from "../../models";

const MaterialAdmin = () => {
	const title = useRef("");
	const documentFile = useRef("");
	const categories = useRef([]);

	const handleCreate = async (e) => {
		e.preventDefault();
		if (!title.current.value || !categories.current.value) {
			alert("fill out empty fields");
		} else {
			const url = await uploadFile(documentFile.current.files[0]);
			const success = await createMaterial(title.current.value, url, categories.current.value);
			success ? alert("success") : alert("error");
		}
	};

	return (
		<>
			<div className="w-full md:w-1/2">
				<form className="flex flex-col">
					<input type="file" className="border-2 rounded-md px-4 py-3" ref={documentFile} />
					<label className="text-gray-900 dark:text-white">Titel</label>
					<input
						className="border-2 border-gray-200 dark:bg-gray-800 pl-2 text-lg py-1 rounded-md placeholder-gray-300 focus:placeholder-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
						placeholder="Titel"
						ref={title}
						type="text"
					/>
					<label className="text-gray-900 dark:text-white">Kategorien (mit "," trennen)</label>
					<input
						className="border-2 border-gray-200 dark:bg-gray-800 pl-2 text-lg py-1 rounded-md placeholder-gray-300 focus:placeholder-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
						placeholder="Kategorien"
						ref={categories}
						type="text"
					/>
					<button className="border-2 border-blue-400 my-5 p-4 text-blue-400" onClick={handleCreate}>
						Hochladen
					</button>
				</form>
			</div>
		</>
	);
};

const uploadFile = async (file) => {
	const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`;
	const timeStamp = Date.now() / 1000;
	let formData = new FormData();
	formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
	formData.append("file", file);
	formData.append("timestamp", timeStamp);
	formData.append("upload_preset", process.env.REACT_APP_PRESET_PDF);
	let respData = await axios.post(url, formData);
	return respData.data.secure_url;
};

export default MaterialAdmin;
