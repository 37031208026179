import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import CategorieColors from "../utils/CategorieColors";

const MaterialPreview = ({ id, title, materialurl, categories }) => {
	/* make PDF resizeable */

	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});

	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
		doPDFSize();
	};

	useEffect(() => {
		doPDFSize();
		window.addEventListener("resize", setDimension);

		return () => {
			window.removeEventListener("resize", setDimension);
		};
	});

	function doPDFSize() {
		if (screenSize.dynamicWidth > 640) {
			setPDFWidth(384);
		} else {
			setPDFWidth(320);
		}
	}

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pdfWidth, setPDFWidth] = useState(384);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const handleNext = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	};

	const handlePrev = () => {
		if (pageNumber >= 2) {
			setPageNumber(pageNumber - 1);
		}
	};

	return (
		<>
			<div className="w-80 sm:w-96 mx-5 my-5" id={id}>
				<div className="rounded overflow-hidden shadow-lg bg-white dark:bg-gray-700">
					<div className="relative">
						<div className="w-full">
							<Document
								className="w-full"
								error="PDF Datei konnte nicht geladen werden"
								loading="PDF wird vorbereitet"
								renderMode="svg"
								file={materialurl}
								onLoadSuccess={onDocumentLoadSuccess}>
								<Link to={`/Material/${id}`}>
									<Page
										className="border-b-4 border-blue-500"
										width={pdfWidth}
										pageNumber={pageNumber}
									/>
								</Link>
								<div className="w-44 h-10 rounded-md bg-gray-200 text-gray-900 dark:text-white dark:bg-gray-700 drop-shadow-lg relative bottom-14 z-10 flex flex-row justify-center mx-auto text-gray-300">
									<button
										onClick={handlePrev}
										className="p-2 hover:bg-gray-400 dark:hover:bg-gray-500 w-full rounded-md">
										prev
									</button>
									<div className="flex flex-row items-center mx-2">
										<span>{pageNumber}</span>
										<span className="px-1">von</span>
										<span>{numPages}</span>
									</div>
									<button
										onClick={handleNext}
										className="p-2 hover:bg-gray-400 dark:hover:bg-gray-500 w-full rounded-md">
										next
									</button>
								</div>
							</Document>
						</div>
					</div>
					<div className="px-2 pb-4 relative -top-6">
						<div className="mb-4 flex flex-row items-center">
							<div className="pb-2 flex overflow-x-auto flex-nowrap">
								{categories.map((categorie, key) => (
									<CategorieColors categorie={categorie} key={key} />
								))}
							</div>
						</div>
						<Link
							to={`/Entry/${id}`}
							className="font-semibold text-lg inline-block text-gray-900 dark:text-white hover:text-indigo-600 transition duration-500 ease-in-out">
							{title}
						</Link>
						<p className="text-gray-500 text-sm">PDF</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default MaterialPreview;
