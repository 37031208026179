import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <button
      className="px-2 py-2 w-full text-left"
      onClick={() => loginWithRedirect()}
    >
      Anmelden
    </button>
  );
};

export default LoginButton;