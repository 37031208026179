import { Link } from "react-router-dom";
import React from "react";

const EntryPreview = ({ id, title, author, avatar, date, tags }) => {
	const months = [
		"Januar",
		"Februar",
		"März",
		"April",
		"Mai",
		"Juni",
		"Juli",
		"August",
		"September",
		"Oktober",
		"November",
		"Dezember",
	];

	const month_arr = date.split("-");

	const day = month_arr[2];

	const month = months[Number(month_arr[1]) - 1];

	const year = month_arr[0];

	var size = "c_fill,h_768,w_640/f_webp/";
	var position = 68;
	const transformedUrl = avatar.substring(0, position) + size + avatar.substring(position);
	return (
		<>
			<div className="w-80 md:w-96 mx-5 my-5" id={id}>
				<div className="rounded overflow-hidden shadow-lg bg-white dark:bg-gray-700">
					<Link to={`/Entry/${id}`}>
						<div className="relative">
							<img className="w-full h-80 object-cover" src={transformedUrl} alt={title} />
							<div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
							<div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
								{year}
							</div>
							<div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
								<span className="font-bold">{day}</span>
								<small>{month}</small>
							</div>
						</div>
					</Link>
					<div className="px-6 py-4">
						<Link
							to={`/Entry/${id}`}
							className="font-semibold text-lg inline-block text-gray-900 dark:text-white hover:text-indigo-600 transition duration-500 ease-in-out">
							{title}
						</Link>
						<p className="text-gray-500 text-sm">Beitrag von: {author}</p>
					</div>
					<div className="px-6 py-4 flex flex-row items-center">
						<div className="pt-4 pb-2 flex overflow-x-auto flex-nowrap">
							{tags.map((tag, key) => (
								<span
									key={key}
									className="inline-block bg-gray-200 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-white mr-2 mb-2">
									{tag}
								</span>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EntryPreview;
