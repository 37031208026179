import React,{useState} from 'react';
//import {Link} from 'react-router-dom'

import LoginButton from './LoginButton';

import Microphone from '../images/microphone.svg';
import Close from '../images/close.svg';

import { useAuth0 } from '@auth0/auth0-react';


const Banner = ({show, message}) => {

    const {isAuthenticated} = useAuth0();

    const [showMessage, setShowMessage] = useState(show)
    
    return(
        <>
            <div className={" mb-5 w-11/12 m-auto rounded-md " + (showMessage? "" : " hidden ") + (message.error? "bg-red-500" : "bg-green-500")}>
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
                            <span className={"flex p-2 rounded-lg " + (message.error? "bg-red-700" : "bg-green-700")}>
                               <img src={Microphone} alt="microphone" className="h-6 w-6 text-white"/>
                            </span>
                            <p className="ml-3 font-medium text-white truncate">
                                <span className="md:hidden">{message.msgShort}</span>
                                <span className="hidden md:inline">{message.msg}</span>
                            </p>
                        </div>
                        {isAuthenticated ?
                            <div>
                            </div>
                            :
                            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto text-white bg-red-600 rounded-md">
                                <LoginButton />
                            </div>
                        }
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                            <button type="button" onClick={() => setShowMessage(false)} className={"-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 " + (message.error? "hover:bg-red-500" : "hover:bg-green-500")}>
                                <span className="sr-only">Dismiss</span>
                                <img src={Close} className="h-6 w-6" alt="close" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      </>
    )
}

export default Banner;