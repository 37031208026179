import React, { useEffect, useState } from "react";

import BlogPreview from "../Elements/EntryPreview";

import { getEntrys } from "../../models";

import ShowSkeletons from "./ShowSkeletons";

const ShowEntrys = () => {
	const [entrys, setEntrys] = useState([]);
	useEffect(() => {
		async function fetchEntrys() {
			// You can await here
			let data = await getEntrys();
			setEntrys(data);
		}
		fetchEntrys();
	}, []);
	let sorted_entrys = entrys
		.slice()
		.sort((a, b) => Date.parse(new Date(b.data.created__at)) - Date.parse(new Date(a.data.created__at)));
	return (
		<>
			<div className="flex flex-col justify-evenly items-center md:items-start md:flex-row md:flex-wrap md:w-11/12 m-auto">
				{entrys.length > 0 ? (
					sorted_entrys.map((entry, idx) => (
						<BlogPreview
							key={idx}
							id={entry.ref.value.id}
							title={entry.data.title}
							author={entry.data.author}
							avatar={entry.data.avatar}
							date={entry.data.created__at}
							tags={entry.data.tags}
						/>
					))
				) : (
					<ShowSkeletons />
				)}
			</div>
		</>
	);
};

export default ShowEntrys;
