import React, { useState, useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import AuthNav from "./AuthNav";

import { useAuth0 } from "@auth0/auth0-react";

import NavIcons from "./NavIcons";

const NavBar = () => {
	const { user, isAuthenticated } = useAuth0();

	const navigation = [
		{ name: "Home", to: "/", icon: "home" },
		{ name: "Profil", to: "/Profile", icon: "user" },
		{ name: "Neu", to: "/New", icon: "add" },
		{ name: "Dokumente", to: "/Info", icon: "info" },
		{ name: "Admin", to: "/Admin", icon: "admin" },
		{ name: "Kalender", to: "/Kalender", icon: "calendar" },
	];

	const [menuOpen, setMenuOpen] = useState(false);
	const toggle = () => setMenuOpen(!menuOpen);
	const hide = () => setMenuOpen(false);
	const [hasRolePermissionAdmin, setHasRolePermissionAdmin] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			if (user["http://hbv2TokenRole/roles"][0] === "Admin") {
				setHasRolePermissionAdmin(true);
			} else {
				setHasRolePermissionAdmin(false);
			}
		}
	}, [user, isAuthenticated]);

	const getActiveClass = (path) => {
		return window.location.pathname === path ? "bg-indigo-300" : "";
	};

	return (
		<>
			<nav className="flex justify-between flex-col lg:flex-row flex-nowrap bg-indigo-500 w-11/12 rounded-md m-auto mt-4 mb-2 text-white shadow-sm">
				<div className="flex flex-row flex-nowrap items-center justify-between my-3 py-1 lg:my-5">
					<div className="">
						<Link to="/" onClick={hide} className="font-semibold ml-10">
							Hausbuch
						</Link>
					</div>
					<div className="lg:hidden">
						<button
							className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
							type="button"
							onClick={() => setMenuOpen(!menuOpen)}>
							<span className="block relative w-6 h-1 rounded-sm bg-white"></span>
							<span className="block relative w-6 h-1 rounded-sm bg-white mt-1"></span>
							<span className="block relative w-6 h-1 rounded-sm bg-white mt-1"></span>
						</button>
					</div>
				</div>
				<div className={(menuOpen ? " flex " : " hidden ") + "lg:flex items-center lg:mr-10"}>
					<div className="flex flex-col lg:flex-row flex-nowrap w-full my-2">
						{navigation.map((item, id) => {
							return item.name === "Admin" && !hasRolePermissionAdmin ? (
								<div key={id}></div>
							) : (
								<div
									key={id}
									className={
										"flex flex-row items-center px-2 hover:bg-indigo-300 rounded-md mx-2 " +
										getActiveClass(item.to)
									}>
									<NavIcons icon={item.icon} />
									<NavLink
										key={id}
										to={item.to}
										onBlur={hide}
										onClick={toggle}
										className="px-2 py-2 w-full">
										{item.name}
									</NavLink>
								</div>
							);
						})}
						{}
						<AuthNav />
					</div>
				</div>
			</nav>
		</>
	);
};

export default NavBar;
