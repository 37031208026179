import React from 'react';

const NotFound = () => {

    return(
        <>
            <h2>Diese Seite konnte nicht gefunden werden</h2>
        </>
    )
}

export default NotFound;