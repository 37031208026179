import React from 'react';

import Skeleton from './Skeleton';


const ShowSkeletons = () => {

    return(
        <>
            <div className="flex flex-col justify-evenly items-center my-10 md:items-start md:flex-row md:flex-wrap md:w-11/12 m-auto">
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
        </>
    )
}

export default ShowSkeletons;