import React from 'react';

const PageDescription = ({headline, tagline}) => {

    return(
        <>
            <div className="flex flex-col w-11/12 mx-auto my-8">
                <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">{headline}</h2>
                <span className="text-md font-normal text-gray-400">{tagline}</span>
            </div>
        </>
    )
}

export default PageDescription;