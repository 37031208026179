import React, { useState, useEffect } from "react";

import ShowEntrys from "../Elements/ShowEntrys";
import ShowSkeletons from "../Elements/ShowSkeletons";
import Banner from "../Elements/Banner";
import PageDescription from "../Elements/PageDescription";
//import useAuthName from "../hooks/useAuthName.js";

import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
	const { isAuthenticated } = useAuth0();
	const [message, setMessage] = useState([false, { msg: "", msgShort: "", error: false }]);
	//const [authName, setAuthName] = useState("");

	//isAuthenticated ? setAuthName(useAuthName()) : setAuthName("");

	useEffect(() => {
		isAuthenticated
			? setMessage([false, { msg: "", msgShort: "", error: false }])
			: setMessage([
					true,
					{
						msg: "Bitte melden sie sich an um alle Inhalte der Seite sehen zu können!",
						msgShort: "Anmelden um Inhalt zu sehen",
						error: true,
					},
			  ]);
	}, [setMessage, isAuthenticated]);

	return (
		<>
			{isAuthenticated ? (
				<div>
					<PageDescription
						headline={"Willkommen"}
						tagline="hier findest du die neusten Einträge in das Hausbuch"
					/>
					<ShowEntrys />
				</div>
			) : (
				<div>
					<Banner key={message[0]} show={message[0]} message={message[1]} />
					<PageDescription
						headline="Willkommen"
						tagline="Das ist die Digitale Version des Hausbuches aus Venterol"
					/>
					<ShowSkeletons />
				</div>
			)}
		</>
	);
};

export default Home;
