import React from "react";

const CategorieColors = ({ categorie }) => {
	if (categorie === undefined) {
		return (
			<span className="inline-block bg-gray-200 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-white mr-2 mb-2">
				{categorie}
			</span>
		);
	}
	return (
		<>
			{(() => {
				switch (categorie) {
					case "Anleitung":
						return (
							<span className="inline-block bg-green-500 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-white dark:text-white mr-2 mb-2">
								{categorie}
							</span>
						);
					case "Wasser":
						return (
							<span className="inline-block bg-blue-500 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-white dark:text-white mr-2 mb-2">
								{categorie}
							</span>
						);
					case "Schwimmbad":
						return (
							<span className="inline-block bg-blue-300 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-white dark:text-white mr-2 mb-2">
								{categorie}
							</span>
						);

					default:
						return (
							<span className="inline-block bg-gray-500 dark:bg-blue-400 rounded-full px-3 py-1 text-sm font-semibold text-white dark:text-white mr-2 mb-2">
								{categorie}
							</span>
						);
				}
			})()}
		</>
	);
};

export default CategorieColors;
