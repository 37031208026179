import React, { useState, useRef } from "react";

import { createEntry } from "../../models";
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import {useHistory} from 'react-router-dom'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Banner from "../Elements/Banner.js";
import PageDescription from "../Elements/PageDescription";

import Spinner from "../Elements/Spinner";

const New = () => {
	const [message, setMessage] = useState([false, { msg: "", msgShort: "", error: true }]);

	const BannerMsg = (show, msg, msgShort, error) => {
		setMessage([
			show,
			{
				msg: msg,
				msgShort: msgShort,
				error: error,
			},
		]);
	};

	const { user } = useAuth0();
	const { name } = user;

	//const history = useHistory()

	const [content, setContent] = useState("<h2>Eintrag hier schreiben...</h2>");
	const [isUploading, setIsUploading] = useState(false);
	const [fileIsValid, setFileIsValid] = useState(false);

	const tags = useRef("");
	const title = useRef("");
	const avatar = useRef("");

	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth() + 1;
	var yyyy = today.getFullYear();
	if (dd < 10) {
		dd = "0" + dd;
	}
	if (mm < 10) {
		mm = "0" + mm;
	}
	today = yyyy + "-" + mm + "-" + dd;
	const [date, setDate] = useState(today);

	const sizeCheck = (file) => {
		if (file !== undefined) {
			if (file.size < 10475274) {
				setFileIsValid(true);
				BannerMsg(false, "", "", false);
			} else {
				setFileIsValid(false);
				BannerMsg(
					true,
					"Das Bild ist zu groß, bitte wähle eine andere Datei! Die maximale Dateigröße beträgt 10MB",
					"Das Bild ist zu groß, Max. 10MB",
					true
				);
			}
		}
	};

	const handleCreate = async (e) => {
		setIsUploading(true);
		e.preventDefault();
		if (!title.current.value || !tags.current.value || !avatar.current.value) {
			setIsUploading(false);
			BannerMsg(
				true,
				"Erst müssen Bild, Titel sowie Tags und Datum eingegeben werden",
				"Bild,Titel,Tags und Datum ausfüllen",
				true
			);
		} else {
			const url = await uploadFile(avatar.current.files[0]);
			const success = await createEntry(
				title.current.value,
				content,
				url,
				tags.current.value.split(","),
				name,
				date
			);
			success
				? BannerMsg(
						true,
						"Der Eintrag in das Hausbuch wurde erfolgreich erstellt",
						"Eintrag erfolgreich!",
						false
				  )
				: BannerMsg(true, "Fehler beim Hochladen", "Fehler beim hochladen", true);
			setIsUploading(false);
		}
	};

	return (
		<>
			<Banner key={message[0]} show={message[0]} message={message[1]} />
			<PageDescription
				headline="Neuer Eintrag"
				tagline="hier kann man einen Neuen Eintrag in das Hausbuch verfassen"
			/>
			<div className="w-11/12 lg:w-3/4 m-auto flex flex-col">
				<form className="flex flex-col lg:flex-row lg:flex-wrap">
					<div className="flex flex-col mb-10 w-full">
						<label className="text-lg font-semibold text-gray-900 dark:text-white" htmlFor="upload">
							Bild hinzufügen
						</label>
						<input
							type="file"
							className={
								"border-2 rounded-md px-4 py-3 " +
								(fileIsValid ? "border-green-400 placeholder-green-400" : "border-red-400")
							}
							ref={avatar}
							onChange={(e) => sizeCheck(e.target.files[0])}
						/>
					</div>
					<div className="flex flex-col mb-5 w-full">
						<label className="text-lg font-semibold text-gray-900 dark:text-white" htmlFor="title">
							Titel:{" "}
						</label>
						<input
							className="border-2 border-gray-200 dark:bg-gray-800 pl-2 text-xl py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
							ref={title}
							type="text"
							name="title"
						/>
					</div>
					<div className="flex flex-col mb-10 lg:w-1/2 lg:mr-10">
						<label className="text-lg font-semibold text-gray-900 dark:text-white" htmlFor="tags">
							Tags: (mit komma trennen)
						</label>
						<input
							className="border-2 border-gray-200 dark:bg-gray-800 pl-2 text-lg py-1 rounded-md placeholder-gray-300 focus:placeholder-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
							placeholder="Tag1, Tag2, Tag3"
							ref={tags}
							type="text"
						/>
					</div>
					<div className="flex flex-col mb-10">
						<label className="text-lg font-semibold text-gray-900 dark:text-white" htmlFor="tags">
							Datum:
						</label>
						<input
							className="border-2 border-gray-200 dark:bg-gray-800 text-lg text-gray-300 pl-2 py-1 rounded-md bg-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500"
							type="date"
							defaultValue={today}
							onChange={(e) => setDate(e.target.value)}
						/>
					</div>
					<div className="mb-10 w-full" id="ck-content">
						<CKEditor
							editor={ClassicEditor}
							data={content}
							row={100}
							config={{
								toolbar: [
									"heading",
									"|",
									"bold",
									"italic",
									"blockQuote",
									"link",
									"numberedList",
									"bulletedList",
									"insertTable",
									"|",
									"undo",
									"redo",
								],
							}}
							onReady={(editor) => {}}
							onChange={(event, editor) => {
								const data = editor.getData();
								setContent(data);
							}}
						/>
					</div>
					<div className="mb-10">
						<button
							onClick={handleCreate}
							disabled={!fileIsValid}
							type="submit"
							className={
								(fileIsValid ? "cursor-pointer" : "cursor-not-allowed") +
								" border-2 border-blue-400 hover:border-blue-700 text-blue-500 font-normal px-4 py-3 rounded-md"
							}>
							{isUploading ? <Spinner /> : <p>Eintrag veröffentlichen</p>}
						</button>
						<br />
						<br />
						{isUploading ? (
							<span className="text-yellow-500 text-md">
								Es kann bis zu 10sec. dauern bis der Beitrag hochgeladen wurde, bitte die Seite solange
								nicht verlassen!
							</span>
						) : (
							<br />
						)}
					</div>
				</form>
			</div>
		</>
	);
};

const uploadFile = async (file) => {
	const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`;
	const timeStamp = Date.now() / 1000;
	let formData = new FormData();
	formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_KEY);
	formData.append("file", file);
	formData.append("timestamp", timeStamp);
	formData.append("upload_preset", process.env.REACT_APP_PRESET);
	let respData = await axios.post(url, formData);
	return respData.data.secure_url;
};

export default New;
