import React, { useEffect, useState } from "react";

import MaterialPreview from "../Elements/MaterialPreview";
import PageDescription from "../Elements/PageDescription";
import { getInfos } from "../../models";

const Info = () => {
	const [material, setMaterial] = useState([]);

	useEffect(() => {
		async function fetchEntrys() {
			// You can await here
			let data = await getInfos();
			setMaterial(data);
		}
		fetchEntrys();
	}, []);

	return (
		<>
			<PageDescription headline="Info Material" tagline="hier gibt es nützliche Dokumente rund um das Häuschen" />
			<div className="flex flex-col justify-evenly items-center md:items-start md:flex-row md:flex-wrap w-full w-11/12 mx-auto">
				{material.length ? (
					material.map((info, idx) => (
						<MaterialPreview
							key={idx}
							id={info.ref.value.id}
							title={info.data.title}
							materialurl={info.data.url}
							categories={info.data.categories}
						/>
					))
				) : (
					<p>##Es sind noch keine Dokumente vorhanden##</p>
				)}
			</div>
		</>
	);
};

export default Info;
