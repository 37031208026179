// src/components/auth-nav.js

import React from "react";
import AuthenticationButton from "./AuthenticationButton";

import NavIcons from "./NavIcons";


import { useAuth0 } from "@auth0/auth0-react";


const AuthNav = () => {

const {isAuthenticated} = useAuth0();

return(
    <>
    <div className="flex flex-row items-center px-2 hover:bg-indigo-300 rounded-md mx-2">
        <NavIcons icon={isAuthenticated? 'signout' : 'signin'} />
        <AuthenticationButton />
    </div>
    </>
)};

export default AuthNav;