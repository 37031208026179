import faunadb, { query as q } from "faunadb";

const client = new faunadb.Client({ secret: process.env.REACT_APP_FAUNA_KEY });

export const createEntry = async (title, body, avatar, tags, author, created__at) => {
	try {
		let data = await client.query(
			q.Create(q.Collection("entrys"), {
				data: {
					title,
					body,
					created__at,
					author,
					avatar,
					tags,
				},
			})
		);
		data.data.id = data.ref.value.id;
		return true;
	} catch (error) {
		return false;
	}
};

export const createMaterial = async (title, url, categories) => {
	try {
		let data = await client.query(
			q.Create(q.Collection("material"), {
				data: {
					title: title,
					url: url,
					categories: categories.replace(/\s+/g, "").split(","),
				},
			})
		);
		data.data.id = data.ref.value.id;
		return true;
	} catch (error) {
		return false;
	}
};

export const createEvent = async (eData) => {
	try {
		let data = await client.query(
			q.Create(q.Collection("cal_events"), {
				data: eData,
			})
		);
		data.data.id = data.ref.value.id;
		return true;
	} catch (error) {
		return false;
	}
};

export const updateEntry = async (title, body, tags, created__at, id) => {
	client.query(
		q.Update(q.Ref(q.Collection("entrys"), id), {
			data: {
				title: title,
				body: body,
				tags: tags,
				created__at: created__at,
			},
		})
	);
	//return console.log(data)
};

export const updateEvent = async (eData) => {
	client.query(
		q.Update(q.Ref(q.Collection("cal_events"), eData.id), {
			data: eData,
		})
	);
};

export const deleteEntry = async (id) => {
	client.query(q.Delete(q.Ref(q.Collection("entrys"), id)));
	return true;
};

export const getEntrys = async () => {
	let allEntrys = await client.query(
		q.Map(q.Paginate(q.Documents(q.Collection("entrys"))), q.Lambda("X", q.Get(q.Var("X"))))
	);
	return allEntrys.data;
};

export const getInfos = async () => {
	let allInfos = await client.query(
		q.Map(q.Paginate(q.Documents(q.Collection("material"))), q.Lambda("X", q.Get(q.Var("X"))))
	);
	return allInfos.data;
};

export const getEvents = async () => {
	let allEvents = await client.query(
		q.Map(q.Paginate(q.Documents(q.Collection("cal_events"))), q.Lambda("X", q.Get(q.Var("X"))))
	);
	return allEvents.data;
};
export const getUsers = async () => {
	let allUsers = await client.query(
		q.Map(q.Paginate(q.Documents(q.Collection("users"))), q.Lambda("X", q.Get(q.Var("X"))))
	);
	return allUsers.data;
};

export const getEntrysWhereName = async (name) => {
	let EntrysWhereName = await client.query(
		q.Map(q.Paginate(q.Match(q.Index("entry_by_name"), name)), q.Lambda("X", q.Get(q.Var("X"))))
	);
	return EntrysWhereName.data;
};

export const getEntry = async (id) => {
	try {
		let entry = await client.query(q.Get(q.Ref(q.Collection("entrys"), id)));
		entry.data.id = entry.ref.value.id;
		return entry.data;
	} catch (error) {
		return undefined;
	}
};

export const getMaterial = async (id) => {
	try {
		let info = await client.query(q.Get(q.Ref(q.Collection("material"), id)));
		info.data.id = info.ref.value.id;
		return info.data;
	} catch (error) {
		return undefined;
	}
};
