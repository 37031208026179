// src/components/logout-button.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <button className="px-2 py-2 w-full text-left"
                      onClick={() =>
                        logout({
                          returnTo: window.location.origin,
                        })
                      }>
                      Ausloggen
                    </button>
  );
};

export default LogoutButton;