import { Calendar, Views, luxonLocalizer } from "react-big-calendar";
import React, { useEffect, useState } from "react";

import AddEvent from "../Elements/AddEvent";
import { DateTime } from "luxon";
import EditEvent from "../Elements/EditEvent";
import { getEvents } from "../../models";

const lcl = luxonLocalizer(DateTime);

const CalendarView = () => {
	const [events, setEvents] = useState([
		{
			id: 0,
			title: "Initial Event",
			allDay: true,
			start: new Date(2015, 3, 0),
			end: new Date(2015, 3, 1),
		},
	]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [showEditForm, setShowEditForm] = useState(false);
	const [editEventData, setEditEventData] = useState(undefined);

	const deActivateAddForm = () => {
		setShowAddForm(false);
	};
	const deActivateEditForm = () => {
		setShowEditForm(false);
	};
	/*function eventStyleGetter(event, start, end, isSelected) {
		console.log(event);
		var backgroundColor = "#" + event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			borderRadius: "0px",
			opacity: 0.8,
			color: "black",
			border: "0px",
			display: "block",
		};
		return {
			style: style,
		};
	}*/

	const editEvent = (calEvent) => {
		setEditEventData(calEvent);
		setShowEditForm(true);
		//console.log(calEvent);
	};

	useEffect(() => {
		async function fetchEvents() {
			let data = await getEvents();
			//console.log(data);
			data.map((event) => {
				return setEvents((e) => {
					return [
						...e,
						{
							id: event.ref.id,
							title: event.data.title,
							allDay: true,
							start: new Date(event.data.start),
							end: new Date(event.data.end),
						},
					];
				});
			});
		}
		fetchEvents();
	}, []);

	console.log(events);

	return (
		<>
			<AddEvent deActivateAddForm={deActivateAddForm} showForm={showAddForm} />
			<EditEvent deActivateEditForm={deActivateEditForm} showForm={showEditForm} eventData={editEventData} />
			<div className="w-11/12 h-screen mx-auto flex flex-col">
				<div className="flex flex-col justify-center">
					<div className="flex flex-row justify-around items-center">
						<button
							className="border-2 border-green-500 text-green-500 hover:border-green-600 px-6 py-3 text-lg font-semibold text-white rounded-md mb-10 mt-6"
							onClick={() => setShowAddForm(!showAddForm)}>
							Aufenthalt hinzufügen
						</button>
					</div>
				</div>
				<Calendar
					localizer={lcl}
					events={events}
					startAccessor="start"
					endAccessor="end"
					defaultView={Views.MONTH}
					views={["month"]}
					eventPropGetter={(event, start, end, isSelected) => ({
						event,
						start,
						end,
						isSelected,
						style: { backgroundColor: "#204f6c" },
					})}
					onSelectEvent={editEvent}
				/>
			</div>
		</>
	);
};

export default CalendarView;
