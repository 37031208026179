// src/views/profile.js

import React, { useEffect, useState } from "react";

import PageDescription from "../Elements/PageDescription";
import ShowEntrysByName from "../Elements/ShowEntrysByName";
import { getEntrysWhereName } from "../../models";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
	const { user } = useAuth0();
	const { name } = user;

	const [entrysWhereName, setEntrysWhereName] = useState([]);

	useEffect(() => {
		async function fetchEntrys() {
			let dataName = await getEntrysWhereName(name);
			setEntrysWhereName(dataName);
		}
		fetchEntrys(name);
	}, [name]);
	console.log(user);
	const num_entrys = Object.keys(entrysWhereName).length;
	let sorted_entrys = entrysWhereName
		.slice()
		.sort((a, b) => Date.parse(new Date(b.data.created__at)) - Date.parse(new Date(a.data.created__at)));

	return (
		<>
			<PageDescription headline={name} tagline={"Beiträge: " + num_entrys} />
			{/* <div className="h-96 w-full bg-white dark:bg-gray-800 flex justify-center items-center">
				<div className="h-56 w-72 absolute flex justify-center items-center">
					<img className="object-cover h-20 w-20 rounded-full" alt="Profile" src={picture} />
				</div>
				<div className=" h-80 mx-4 w-5/6 bg-indigo-500 rounded-3xl shadow-md sm:w-80 sm:mx-0">
					<div className="h-1/2 w-full flex justify-between items-baseline px-3 py-5">
						<h1 className="text-white">Profil</h1>
					</div>
					<div className=" bg-white dark:bg-gray-600 h-1/2 w-full rounded-3xl flex flex-col justify-around items-center">
						<div className="w-full h-1/2 flex justify-between items-center px-3 pt-2">
							<div className="flex flex-col justify-center items-center">
								<h1 className="dark:text-white text-xs">Beiträge</h1>
								<h1 className="dark:text-gray-50 text-sm">{num_entrys}</h1>
							</div>
							<div className="flex flex-col justify-center items-center">
								<h1 className="dark:text-white text-xs">Nickname</h1>
								<h1 className="dark:text-gray-50 text-sm">{nickname}</h1>
							</div>
						</div>
						<div className="w-full h-1/2 flex flex-col justify-center items-center">
							<h1 className="dark:text-white font-bold">{name}</h1>
							<h1 className="dark:text-gray-50 text-sm">{email}</h1>
						</div>
					</div>
				</div>
			</div> */}
			<ShowEntrysByName data={sorted_entrys} />
		</>
	);
};

export default Profile;
