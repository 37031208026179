import React, {useEffect, useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import AdminView from './AdminView';


const Dashboard = () => {

    const { user } = useAuth0();

  const [hasRolePermissionAdmin, setHasRolePermissionAdmin] = useState(false);

  useEffect(() => {
    if(user['http://hbv2TokenRole/roles'][0] === 'Admin'){
    setHasRolePermissionAdmin(true);
  }else{
    setHasRolePermissionAdmin(false);
  }
  }, [user])

    return(
        <>
           {hasRolePermissionAdmin?
            <AdminView />
            :
            <p>Sie haben sich wohl verlaufen... Sie scheinen keine Admin Rechte zu besitzen!</p>
        }
        </>
    )
}

export default Dashboard;