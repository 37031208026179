import React from "react";
import { updateEvent } from "../../models";
import { useForm } from "react-hook-form";

const EditEvent = ({ showForm, eventData, deActivateEditForm }) => {
	console.log(eventData);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ defaultValues: { title: "" } });

	return (
		<>
			<form
				className={
					(showForm ? "flex" : "hidden") +
					" flex-col justify-center items-center fixed bg-white border-2 border-indigo-500 top-0 left-0 w-screen h-screen z-10"
				}
				onSubmit={handleSubmit((data) => {
					console.log(data);
					updateEvent(data);
					deActivateEditForm();
				})}>
				<button
					onClick={() => {
						deActivateEditForm();
					}}
					type="button"
					className="text-xl font-normal relative -top-40 border-b-2 border-indigo-500">
					schließen
				</button>
				<div className="flex flex-col justify-center items-center">
					<div className="flex flex-col w-full">
						<input type="hidden" {...register("id", { required: true })} />
						<p className="my-2 font-semibold">Mitreisende</p>
						<input
							className="border-2 border-indigo-500 w-full rounded-md px-3 py-1"
							type="text"
							{...register("title", {
								required: "Es muss mindestens eine Person genannt werden",
							})}
						/>
						<span className="text-red-400 text-sm">{errors.title?.message}</span>
					</div>

					<div className="flex flex-row w-full">
						<div className="flex flex-col w-11/12 mr-2">
							<p className="my-2 font-semibold">von: </p>
							<input
								className="border-2 border-indigo-500 w-full rounded-md px-3 py-1"
								type="date"
								{...register("start", {
									required: "Valides Start-Datum wählen",
								})}
							/>
							<span className="text-red-400 text-sm">{errors.start?.message}</span>
						</div>
						<div className="flex flex-col w-11/12 ml-2">
							<p className=" my-2 font-semibold">bis:</p>
							<input
								className="border-2 border-indigo-500 w-full rounded-md px-3 py-1"
								type="date"
								{...register("end", {
									required: "Valides End-Datum wählen",
								})}
							/>
							<span className="text-red-400 text-sm">{errors.end?.message}</span>
						</div>
					</div>
					<div className="flex flex-col w-full">
						<p className="my-2 font-semibold">Anmerkung</p>
						<input
							className="border-2 border-indigo-500 w-full rounded-md px-3 py-1"
							type="text"
							{...register("info")}
						/>
					</div>
				</div>
				<button
					className="bg-indigo-500 hover:bg-indigo-600 text-white rounded-full px-4 py-2 my-4"
					type="submit">
					Aufenthalt aktualisieren
				</button>
			</form>
		</>
	);
};

export default EditEvent;
