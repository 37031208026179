import React from "react";

const Footer = () => {
	return (
		<>
			<div className="bg-gray-200 dark:bg-gray-600 w-11/12 h-28 flex flex-row justify-between items-center rounded-md my-4 m-auto">
				<div className="ml-10 text-gray-900 dark:text-white">Hausbuch Venterol</div>
				<div className="mr-10">
					<a
						href="https://www.jonasreidel.de"
						target="_blank"
						rel="noreferrer"
						className="text-gray-900 border-b-2 border-blue-500 dark:text-white">
						Made by Jonas Reidel
					</a>
				</div>
			</div>
		</>
	);
};

export default Footer;
