import React from "react";

const EntryAdminControls = ({ hedit, hdelete }) => {
	return (
		<>
			<hr className="mb-5" />
			<div className="flex flex-row">
				<div>
					<button
						onClick={() => {
							hedit();
						}}
						className="bg-indigo-800 text-white ring-2 ring-indigo-400 px-4 py-3 rounded-md hover:bg-indigo-600 mr-5">
						Bearbeiten
					</button>
					<button
						onClick={() => {
							hdelete();
						}}
						className="bg-red-800 text-white ring-2 ring-red-400 px-4 py-3 rounded-md hover:bg-red-600">
						Löschen
					</button>
				</div>
			</div>
		</>
	);
};

export default EntryAdminControls;
