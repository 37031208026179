import React from 'react';

const Skeleton = () => {

    return(
        <>
            <div className="w-80 md:w-96 mx-5 my-5">
                <div className="rounded overflow-hidden shadow-lg">
                        <div className="relative">
                            <div className="relative bottom-0 top-0 right-0 left-0 bg-gray-200 rounded animate-pulse h-48"></div>
                                <div className="absolute bottom-0 left-0 bg-indigo-500 h-4 w-10 px-4 py-2"></div>
                                <div className="text-sm absolute top-0 right-0 bg-indigo-500 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out"></div>
                        </div>
                    <div className="px-6 py-4">
                        <div className="h-8 bg-gray-200 rounded animate-pulse w-full mb-3"></div>
                        <div className="h-8 bg-gray-200 rounded animate-pulse w-1/2"></div>
                    </div>
                    <div className="px-6 py-4 flex flex-row items-center">
                        <div className=" pb-2 flex overflow-x-auto flex-nowrap">
                                <div className="inline-block bg-gray-200 rounded-full px-3 py-1 mr-2 mb-2 h-8 w-24 bg-gray-200 rounded animate-pulse"></div>
                                <div className="inline-block bg-gray-200 rounded-full px-3 py-1 mr-2 mb-2 h-8 w-24 bg-gray-200 rounded animate-pulse"></div>
                                <div className="inline-block bg-gray-200 rounded-full px-3 py-1 mr-2 mb-2 h-8 w-24 bg-gray-200 rounded animate-pulse"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Skeleton;