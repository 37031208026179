import { Link } from "react-router-dom";
import React from "react";

const ShowEntry = ({ ImageUrl, title, day, month, year, author, tags, body }) => {
	var size = "c_fill,h_1280,g_auto/f_webp/";
	var position = 68;
	const transformedUrl = ImageUrl.substring(0, position) + size + ImageUrl.substring(position);

	return (
		<>
			<div className="flex flex-col max-w-2xl mx-auto">
				<Link
					to="/"
					className="border-b-2 border-gray-900 text-gray-900 dark:text-white dark:border-white pb-1 pt-6 max-w-max mb-10">
					zurück{" "}
				</Link>
				<div className="relative">
					<img className="w-full h-auto object-cover" src={transformedUrl} alt={title} />
					<div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>{" "}
					<div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
						<span className="text-lg mx-2">{year}</span>
					</div>
					<div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
						<span className="font-bold">{day}</span>
						<small>{month}</small>
					</div>
				</div>
				<h1 className="text-2xl font-semibold text-gray-900 dark:text-white mt-10 mb-5">{title}</h1>
				<span className="text-md text-gray-400">{`Eintrag von: ${author}`}</span>
				<div className="flex flex-row items-center">
					<div className="pt-4 pb-2 flex overflow-x-auto flex-nowrap">
						{tags
							? tags.map((tag, key) => (
									<span
										key={key}
										className="inline-block bg-gray-200 dark:bg-indigo-600 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-white mr-2 mb-2">
										{tag}
									</span>
							  ))
							: ""}
					</div>
				</div>
				<hr />
				<div
					id="ck-content"
					className="mt-5 mb-10 dark:text-white"
					dangerouslySetInnerHTML={{ __html: body }}></div>
			</div>
		</>
	);
};

export default ShowEntry;
