import React, { useState } from "react";

import Home from "./Components/views/Home.js";
import Dashboard from "./Components/Admin/Dashboard.js";
import Loading from "./Components/Loading";
import Profile from "./Components/views/Profile";
import New from "./Components/views/New.js";
import NotFound from "./Components/views/NotFound.js";
import Entry from "./Components/views/Entry.js";
import Info from "./Components/views/Info.js";
import Material from "./Components/views/Material.js";
import CalendarView from "./Components/views/Calendar.js";

import NavBar from "./Components/Elements/NavBar";
import Footer from "./Components/Elements/Footer";
import Banner from "./Components/Elements/Banner.js";
//import SidebarNav from './Components/Elements/SideBarnav.js';

import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./auth/protected-route";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
	const { isLoading } = useAuth0();

	const [message, setMessage] = useState([false, { msg: "", msgShort: "", error: true }]);

	const showBannerWithMsg = (show, msg, msgShort, error) => {
		setMessage([
			show,
			{
				msg: msg,
				msgShort: msgShort,
				error: error,
			},
		]);
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<div className="">
				<NavBar />
				<Banner key={message[0]} show={message[0]} message={message[1]} />
				<div className="">
					<Switch>
						<Route path="/" exact component={Home} />
						<ProtectedRoute path="/Profile" exact component={Profile} />
						<ProtectedRoute path="/New" exact component={New} />
						<Route path="/Entry/:id">
							<Entry BannerMsg={showBannerWithMsg} />
						</Route>
						<ProtectedRoute path="/Kalender" exact component={CalendarView} />
						<ProtectedRoute path="/Material/:id" component={Material} />
						<ProtectedRoute path="/info" exact component={Info} />
						<ProtectedRoute path="/Admin" exact component={Dashboard} />
						<Route path="*" exact component={NotFound} />
					</Switch>
				</div>
				<Footer />
			</div>
		</>
	);
}

export default App;
