// Import React and Hooks
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Import Sites and Elements
import EntryAdminControls from "../Elements/EntryAdminControls";
//Import Helmet
import { Helmet } from "react-helmet";
import Loading from "../Loading";
//Import external Functions
import ShowEntry from "../Elements/ShowEntry";
import { deleteEntry } from "../../models";
//Import API
import { getEntry } from "../../models";
import { updateEntry } from "../../models";
import { useAuth0 } from "@auth0/auth0-react";

//import EditEntryById from "../Elements/EditEntryById";

const Entry = ({ BannerMsg }) => {
	const { id } = useParams();
	const history = useHistory();

	const [date, setDate] = useState([]);
	const [entryData, setEntryData] = useState({});
	const [edit, setEdit] = useState(false);

	const [content, setContent] = useState();
	const [dateEdit, setDateEdit] = useState();

	const tagsEdit = useRef("");
	const titleEdit = useRef("");

	useEffect(() => {
		const months = [
			"Januar",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember",
		];
		function splitDate(date) {
			const month_arr = date.split("-");
			const day = month_arr[2];
			const month = months[Number(month_arr[1]) - 1];
			const year = month_arr[0];
			setDate([day, month, year]);
		}

		async function fetchEntry() {
			let data = await getEntry(id);
			if (data === undefined) {
				history.push("/");
			}
			setEntryData(data);
			setContent(data.body);
			splitDate(data.created__at);
		}
		fetchEntry();
	}, [id, history]);

	const { user, isAuthenticated } = useAuth0();

	const [hasRolePermissionAdmin, setHasRolePermissionAdmin] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			if (user["http://hbv2TokenRole/roles"][0] === "Admin") {
				setHasRolePermissionAdmin(true);
			} else {
				setHasRolePermissionAdmin(false);
			}
		}
	}, [user, isAuthenticated]);

	const handleEdit = () => {
		setEdit(true);
	};

	const hideEdit = () => {
		setEdit(false);
	};

	const handleDelete = async () => {
		var check = window.confirm("Wollen sie den Eintrag wirklich löschen? \n Diese Aktion ist nicht umkehrbar");
		if (check === false) {
			return;
		}
		const deleted = await deleteEntry(id);
		deleted
			? BannerMsg(true, "Eintrag Erfolgreich gelöscht", "Eintrag Erfolgreich gelöscht", false)
			: BannerMsg(true, "Eintrag konnte nicht gelöscht werden", "Fehler beim löschen", true);
		history.push("/");
	};

	const handleUpdate = async (e) => {
		e.preventDefault();
		await updateEntry(titleEdit.current.value, content, tagsEdit.current.value.split(","), dateEdit, id);
		setEntryData({
			author: entryData.author,
			avatar: entryData.avatar,
			body: content,
			created__at: entryData.created__at,
			id: id,
			tags: tagsEdit.current.value.split(","),
			title: titleEdit.current.value,
		});
		hideEdit();
		BannerMsg(true, "Eintrag erflogreich bearbeitet", "Eintrag erflogreich bearbeitet", false);
	};

	return (
		<>
			<Helmet>
				<meta property="og:title" content={entryData.title} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={"https://www.hausbuch-venterol.de/Entry/" + id} />
				<meta property="og:image" content={entryData.avatar} />
			</Helmet>
			<div className="w-11/12 lg:w-3/4 m-auto flex flex-col">
				{entryData.avatar !== undefined ? (
					<ShowEntry
						ImageUrl={entryData.avatar}
						title={entryData.title}
						day={date[0]}
						month={date[1]}
						year={date[2]}
						author={entryData.author}
						tags={entryData.tags}
						body={entryData.body}
					/>
				) : (
					<Loading />
				)}
				{hasRolePermissionAdmin ? (
					edit ? (
						<form>
							<div className="flex flex-col mb-5 w-full">
								<label className="text-lg font-ubuntu" htmlFor="title">
									Titel:{" "}
								</label>
								<input
									className="border-2 border-gray-200 pl-2 text-xl py-2 rounded-md"
									defaultValue={entryData.title}
									ref={titleEdit}
									type="text"
									name="title"
								/>
							</div>
							<div className="flex flex-row">
								<div className="flex flex-col mb-10 pr-5 lg:w-1/2">
									<label className="text-lg font-ubuntu" htmlFor="tags">
										Tags: (mit komma trennen)
									</label>
									<input
										className="border-2 border-gray-200 pl-2 text-lg py-1 rounded-md placeholder-gray-300 focus:placeholder-gray-200"
										defaultValue={entryData.tags}
										placeholder="Tag1, Tag2, Tag3"
										ref={tagsEdit}
										type="text"
									/>
								</div>
								<div className="flex flex-col mb-10 pl-5 lg:w-1/2">
									<label className="text-lg font-ubuntu" htmlFor="tags">
										Datum: (yyyy-mm-dd)
									</label>
									<input
										className="border-2 border-gray-200 text-lg text-gray-300 pl-2 py-1 rounded-md bg-transparent"
										type="date"
										defaultValue={entryData.created__at}
										onChange={(e) => setDateEdit(e.target.value)}
									/>
								</div>
							</div>
							<div className="mb-10 w-full" id="ck-content">
								<CKEditor
									editor={ClassicEditor}
									data={content}
									row={100}
									config={{
										toolbar: [
											"heading",
											"|",
											"bold",
											"italic",
											"blockQuote",
											"link",
											"numberedList",
											"bulletedList",
											"insertTable",
											"|",
											"undo",
											"redo",
										],
									}}
									onReady={(editor) => {}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContent(data);
									}}
								/>
							</div>
							<button
								onClick={handleUpdate}
								type="submit"
								className="bg-green-800 text-white ring-2 ring-green-400 px-4 py-3 rounded-md hover:bg-green-600 mr-5">
								Speichern
							</button>
							<button
								onClick={hideEdit}
								className="bg-gray-800 text-white ring-2 ring-gray-400 px-4 py-3 rounded-md hover:bg-gray-600 mr-5">
								Abbrechen
							</button>
						</form>
					) : (
						<EntryAdminControls hedit={handleEdit} hdelete={handleDelete} />
					)
				) : (
					<div>Nur Admininistratoren können Beiträge bearbeiten</div>
				)}
			</div>
		</>
	);
};

export default Entry;
