import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getMaterial } from '../../models';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


const Material = () => {

    const {id} = useParams()


    /* get Data from Databse */
    const [materialData, setMaterialData] = useState({});

    useEffect(() => {
        async function fetchEntry() {
            let data = await getMaterial(id)
            if(data === undefined){
                window.location.href = "/";
            }
            setMaterialData(data)
        }
        fetchEntry()
    }, [id])

    /* making PDF Scale to screensize */

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    
    const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight
        })
        setPDFHeight(screenSize.dynamicHeight - 200)
    }
      
    useEffect(() => {
        //setPDFHeight(screenSize.dynamicHeight - 200)
        window.addEventListener('resize', setDimension);
        
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    })

    const [pdfHeight, setPDFHeight] = useState(screenSize.dynamicHeight - 200);

    /* PDF Page logic */

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    const handleNext = () => {
        if(pageNumber < numPages){
            setPageNumber(pageNumber + 1)
        }
    }

    const handlePrev = () => {
        if(pageNumber >= 2){
            setPageNumber(pageNumber - 1)
        }
    }

    /* Render */

    return(
        <>

            <div className="w-11/12 mx-auto">
                <Link to="/Info" className="border-b-2 border-gray-900 text-gray-900 dark:text-white dark:border-white pb-1 pt-6 max-w-max mb-10">zurück </Link>
                <h2 className="my-4 font-semibold text-2xl text-gray-900 dark:text-white">{materialData.title}</h2>
                <Document
                    className="w-auto mx-auto text-center"
                    error="PDF Datei konnte nicht geladen werden"
                    loading="PDF wird vorbereitet"
                    renderMode='svg'
                    file={materialData.url}
                    onLoadSuccess={onDocumentLoadSuccess}>
                        <Page 
                            className="inline-block shadow-md"
                            height={pdfHeight}
                            pageNumber={pageNumber} 
                        />
                        <div className="w-44 h-10 rounded-md bg-gray-200 text-gray-900 dark:text-white dark:bg-gray-700 drop-shadow-lg relative bottom-14 z-10 flex flex-row justify-center mx-auto text-gray-300">
                            <button onClick={handlePrev} className="p-2 hover:bg-gray-400 dark:hover:bg-gray-500 w-full rounded-md">prev</button>
                            <div className="flex flex-row items-center mx-2">
                                <span>{pageNumber}</span>
                                <span className="px-1">von</span>
                                <span>{numPages}</span>
                            </div>
                            <button onClick={handleNext} className="p-2 hover:bg-gray-400 dark:hover:bg-gray-500 w-full rounded-md">next</button>
                        </div>
                </Document>
                
            </div>
            
        </>
    )
}

export default Material;