import React from 'react';

const Loading = () => {
    let circleCommonClasses = 'h-3 w-3 border-2 border-white rounded-full';

    return (
        <>
            <div className='fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-indigo-500 z-10'>
                <div className="flex flex-col m-auto items-center content-center">
                    <span className="text-white font-semibold mb-4 tracking-wider text-xl">Loading...</span>
                    <div className="flex flex-row items-center m-auto">
                        <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
                        <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
                        <div className={`${circleCommonClasses} animate-bounce400`}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loading;