import React from "react";

import { useAuth0 } from "@auth0/auth0-react";

import PageDescription from "../Elements/PageDescription";
import MaterialAdmin from "../Elements/MaterialAdmin";

const AdminView = () => {
	const { user } = useAuth0();
	const { name } = user;

	return (
		<>
			<PageDescription headline={"Hallo " + name + ","} tagline="willkommen auf dem Admin Dashboard" />
			<div className="w-11/12 mx-auto">
				<p className="mb-6">Formular um PDF Dokumente hochzuladen</p>
				<MaterialAdmin />
			</div>
		</>
	);
};

export default AdminView;
